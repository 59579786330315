.wrapper_basket_content {
    .payment_title {
        display: none;
    }

    .payment_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .cards-wrapper {
        .container{
            img {
                position: relative;
                + img{
                    position: absolute;
                    z-index: -1;
                }
            }
        }

    }

    .bank_left_side {
        .wrapper_bloc_banque {
            margin-bottom: 1.25rem;
            padding: 2.5rem;

            #flex {
                display: block;
                width: 100%;
                min-height: 315px;

            }
        }
        .paypal, .bancontact {
            .bloc_bank_contents {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .bank_subtitle.loader_paypal, .bank_subtitle.loader_bancontact {
                    &::before {
                        content: "";
                        display: block;
                        width: 80px;
                        height: 27px;
                        margin-left: 1em;
                        background: $white url(../svg/three_dots_black.svg) no-repeat center/100% auto;
                    }
                }
            }
        }
    }
}
body.checkout {
    .bloc_bank_contents{
        ul.schedule li{
            margin-bottom: 0.5rem;
        }
    }
    #textErrtelephone_3xcb,
    #textErrmobile_3xcb,
    .error_call_bank{
        font-size: 0.78rem;
        color: red;
        top: 31%;
        right: 1rem;
    }
}

/**
 * HIPAY
 */
form#hipay-3-form {
    padding: 0 2rem 1rem 2rem;

    .hipay-3-row {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 1rem;

        .hipay-3-field-container {
            display: flex;
            flex-direction: column;
            width: 100%;

            // With Alias
            #hipay-3-cards {
                height: auto;
                margin-top: 10px;

                .lbl-saved-cards {
                    margin-left: 0;

                    &#pay-other-card {
                        svg {
                            display: none;
                        }
                    }
                }
            }

            .hipay-3-field {
                order: 2;
                height: 40px;
            }

            .hipay-3-label {
                order: 1;
                font-family: Arial, Helvetica, sans-serif;
                font-size: 12px;
                color: #999;
            }

            .hipay-3-baseline {
                order: 3;
                height: 1px;
                background-color: #ccc;
            }

            &-half {
                width: 40%;

                &:last-child {
                    margin-left: 2rem;
                }
            }
        }
    }

    .form_line.saveAlias,
    .form_line.is_default {
        margin-bottom: 1rem;

        label {
            display: flex;
            align-items: center;
            height: 1em;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 12px;
        }
    }

    button[type=submit] {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 240px;
        height: 40px;
        appearance: none;
        border: none;
        background-color: black;
        color: white;
        font-family: sans-serif;
        font-size: 12px;
        text-transform: uppercase;
        cursor: pointer;

        &:disabled {
            background-color: #ccc;
            cursor: default;
        }

        &.loading {
            display: none;

            &+.hipay-loader {
                display: flex;
            }
        }
    }

    .hipay-loader {
        display: none;
        justify-content: center;
        align-items: center;
        width: 240px;
        height: 40px;
        appearance: none;
        border: none;
        background: black url(../svg/loader.svg) no-repeat center;
        background-size: auto 55%;
        cursor: default;
    }

    #hipay-3-error-message {
        margin-top: 1rem;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12px;
        color: red;

        .material-icons {
            font-size: 0;
            margin-right: .4rem;

            &:before {
                content: '►';
                font-size: 12px;
            }
        }
    }
}

.wrapper_bloc_banque {
    .alias_list {
        li {
            margin-top: 1rem;
        }
        form {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 35px;

            .card_type,
            .card_numbers,
            .card_name,
            .btnAliasWrapper {
                margin: 0;
            }

            .card_type {
                width: 50px;
                margin: 0;

                img {
                    width: 100%;
                    height: auto;
                    vertical-align: top;
                }
            }

            .card_numbers,
            .card_name {
                flex: 1;
                text-align: left;
            }

            .card_numbers {
                padding: 0 1rem;
                box-sizing: border-box;
                span:first-child {
                    display: none;
                }
            }

            .btnAliasWrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                flex: 1;

                .use_card {
                    width: 100%;

                    button.use {
                        text-decoration: underline;
                        cursor: pointer;

                        &:hover {
                            text-decoration: none;
                        }

                        &.loading {
                            display: none;

                            &+.loader {
                                display: block;
                            }
                        }
                    }

                    .loader {
                        display: none;
                        height: 16px;

                        span {
                            font-size: 0;

                            &::before {
                                content: '';
                                display: block;
                                width: 16px;
                                height: 16px;
                                background: transparent url(../img/loader.gif) no-repeat center;
                                background-size: contain;
                                margin: 0;
                            }
                        }
                    }
                }

                button.delete {
                    text-decoration: underline;
                    cursor: pointer;
                    right: 5px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}