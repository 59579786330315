.productSizeFieldset,
.productColorFieldset {
    position: relative;
}

.btnAddBasket[disabled], #addToWishlistButton[disabled], #applepay_express_checkout.disabled {
    opacity: .6;
    cursor: default;
    pointer-events: none; /* Needed for the tooltip hover handler */
}

.disabledBtnTooltip {
    position: absolute;
    z-index: -1;
    opacity: 0;
    top: 130%;
    left: 0;
    text-align: center;
    font-size: 1rem;
    transition: all .1s ease;
    border: 2px solid $productTooltipBorderColor;
    color: $productTooltipColor;
    background-color: $productTooltipBgColor;
    white-space: nowrap;
    width: auto;
    min-width: $productTooltipWidth;
    padding: .5rem 1rem;
    box-sizing: border-box;

    .disabledBtnInfos {
        display: block;
        width: 100%;
    }

    &:before {
        content: '';
        border: solid $productTooltipArrowColor;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 6px;
        transform: rotate(-135deg);
        background-color: $productTooltipBgColor;
        position: absolute;
        bottom: 85%;
        left: .8rem;
    }

    &.actif {
        z-index: 1;
        opacity: 1;
    }
}