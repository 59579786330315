body {
    &.newsletter {
        #wrapper_moncompte {
            margin: 0 auto 66px auto;

            .intro {
                margin: 43px 0 40px  0;

                .intro_newsletter {
                    font-size: 20px;
                    line-height: 24px;
                    max-width: 977px;
                    margin: 0 auto;
                }
            }

            .newsletter_text {
                display: flex;
                flex-direction: column;
                row-gap: 22px;
                margin: 44px 0 38px 0;
                color: $primaryColor;

                p {
                    font-size: 15px;
                }

                div {
                    display: flex;
                    column-gap: 20px;
                    align-items: baseline;

                    .bullet {
                        display: block;
                        width: 10px;
                        height: 10px;
                        background-color: $ctaColor;
                        border-radius: 50%;
                    }

                    li {
                        font-size: 15px;
                        font-weight: 700;
                        line-height: 23px;
                        list-style: none;
                        max-width: 59rem;
                        color: $primaryColor;

                        span {
                            font-weight: 400;
                        }
                    }
                }

                span {
                    font-size: 15px;
                    line-height: 20px;
                    color: $primaryColor;
                }
            }

            .img_newsletter {
                display: flex;
                column-gap: 7px;
                margin-bottom: 29px;

                img {
                    width: 100%;
                    height: 100%;

                    &.img {
                        width: 20.063rem;
                        height: auto;
                    }
                }
            }
        }
    }

    .holder_account_intro {
        text-align: center;
        margin: 20px auto 33px auto;
        padding: 5px;
        font-family: $primaryFont;
        font-style: italic;
        font-size: 3.75rem;
        line-height: 60px;
    }

    // INFORMATIONS
    .wrapper_moncompte {
        position: relative;
        margin: 0 auto 91px auto;

        .loading {
            width: 100%;
        }

        .wrapper_moncompte_main {
            max-width: 977px;
            min-height: 500px;
            margin: 0 auto;
        }

        * #btn_add_wishlist {
            width: 288px;
            background-color: $ctaColor;
            color: white;

            &:hover {
                background-color: white;
                color: $ctaColor;
            }
        }

        .erreur_tab {
            color: $red;
            margin-bottom: 10px;
        }

        .update_success {
            margin-bottom: 10px;
        }

        .special {
            display: none;
        }

        .highlight {
            display: none;
        }

        .wrapper_form {
            max-width: 61.5625rem;
            margin: 10px 0;
            width: 100%;
            margin: 0 auto;

            .update_success {
                color: $green;
            }
        }

        .w-form-line {
            #iconErrclientTel {
                display: none;
            }

            .w-radio-group {
                input.w-input-element {
                    display: none;
                }

                .w-input-label {
                    transition: none !important;
                }

                small.w-input-error {
                    margin: -9px 0 4px;
                }
            }

            .w-address-input input.pac-target-input {
                width: 100.5%;
            }
        }

        .left-container {
            // despite being called left-container, this is above and centered in the page
            margin: 0 auto;
            width: 97%;
            display: flex;
            flex-direction: row;
            justify-content: center;
        }

        .wrapper_menu_compte {
            display: flex;
            gap: 3.3438rem;
            justify-content: left;
            font-family: $ctaFont, sans-serif;
            margin-bottom: 2rem;
            padding: 0 4px;

            .faq {
                display: none;
            }

            div {
                font-size: $fs_mid;
                padding-bottom: 0.14rem;
                letter-spacing: 0.2rem;
                line-height: 1.05rem;
                white-space: nowrap;
                text-transform: lowercase;

                &.actif {
                    a {
                        text-decoration: underline;
                        text-underline-offset: 5px;
                    }
                }
            }
        }

        .w-radio-group {
            .w-input-label {
                font-size: 14px !important;
            }
        }

        .moncompte_myinfos_img_holder {
            display: none; // TASK : WP-27886
            position: absolute;
            top: 320px;
            left: -36%;
        }

        //update w-login-form
        .w-login-form, .w-address-form, .w-newuser-form{

            .form-element-wrapper{
                display: flex;
                .has-civility, .addrCivility{
                    width: 50%;
                    margin-right: 1rem;
                }
                .w-form-line:last-of-type{
                    margin-bottom: 1.2rem;
                }
                .update_success{ display: none;}
            }

        }

        .choix_type_adresse {
            display: flex;
            justify-content: left;
            font-family: $ctaFont, sans-serif;
            margin-bottom: 0.8rem;
            margin-top: 2rem;

            div.type_adresse {
                font-size: 14px;
                text-transform: lowercase;
                padding-bottom: 0.25rem;
                letter-spacing: 0.25rem;
                line-height: 1.05rem;
                margin-right: 1.2rem;
                white-space: nowrap;
                cursor: pointer;

                &.actif {
                    color: $ctaColor;
                }
            }

            + .w-form-line {
                display: none !important;
            }
        }

        .wrapper_mes_commandes {
            width: 977px;
            margin: 0 auto;

            .intro {
                font-size: 15px;
                line-height: 20.4px;
            }

            #com_onglet_3 {
                display: none;
            }

            .wrapper_menu_tab {
                .date_select {
                    margin: 32px 0 4px 0;
                    display: inline-block;
                    position: relative;

                    &::after {
                        content: url(../svg/arrow_orange_bottom.svg);
                        position: absolute;
                        right: 10px;
                        top: 16px;
                        height: 20px;
                        width: 20px;
                        font-family: $ctaFont;
                    }

                    select {
                        border: 1px solid $ctaColor;
                        background-color: $whiteColor;
                        border-radius: 0px;
                        font-size: 0.875rem;
                        padding: 11px 47px 14px 13px;
                        margin-top: 4px;
                        color: $ctaColor;
                        letter-spacing: 0.2em;
                        font-family: $ctaFont;
                        text-transform: lowercase;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        position: relative;

                        &:focus-visible {
                            outline: 0px solid $red;
                        }
                    }
                }

                ul {
                    display: inline-block;

                    li {
                        display: inline-block;
                        padding: 5px;
                        line-height: 14px;
                        letter-spacing: 0.2rem;
                        text-transform: uppercase;

                        &:nth-child(1) {
                            padding-left: 30px;
                        }

                        &:nth-child(2) {
                            padding-left: 12px;
                        }

                        &:nth-child(3) {
                            padding-left: 6px;
                        }

                        &:nth-child(4) {
                            padding-left: 14px;
                        }

                        span {
                            font-family: $ctaFont;
                            font-size: 0.875rem;
                            text-transform: lowercase;
                            letter-spacing: 0.2rem;

                            .nb_order {
                                font-family: $primaryFont;
                            }
                        }
                    }
                }
            }

            .table_mon_compte {
                display: block;
                padding: 10px 0px;

                .transporteur {
                    display: none;
                }

                thead {
                    width: 100%;
                    display: table;

                    tr {
                        th {
                            color: $ctaColor;
                            font-family: $ctaFont;
                            font-size: 0.75rem;
                            width: 16.8%;
                            letter-spacing: 0.2em;
                            text-align: left;
                            text-transform: lowercase;

                            &:nth-child(2) {
                                width: 13%;
                            }

                            &:nth-child(3) {
                                width: 12%;
                            }

                            &:nth-child(4) {
                                width: 15%;
                            }

                            &:nth-child(5) {
                                width: 9%;
                            }
                        }
                    }
                }

                tbody {
                    width: 100%;
                    display: table;

                    tr {
                        position: relative;
                        td {
                            color: $primaryColor;
                            font-size: 0.75rem;
                            padding: 38px 0 24px 0;
                            border-bottom: 2px solid $springWood;
                            width: 16.8%;

                            .btn_detail {
                                text-decoration: underline;
                                text-transform: lowercase;
                                cursor: pointer;
                                font-size: 15px;
                            }

                            &.montant {
                                font-size: 15px;
                            }

                            &:nth-child(2) {
                                width: 12.7%;
                            }

                            &:nth-child(3) {
                                width: 11%;
                            }

                            &:nth-child(4) {
                                width: 17%;
                            }

                            &:nth-child(5) {
                                width: 9%;
                            }
                        }
                    }
                }

                thead {
                    width: 100%;
                    display: table;

                    tr {
                        th {
                            color: $ctaColor;
                            font-size: 0.75rem;
                            padding: 32px 0 0 0;
                            width: 16.8%;

                            &:nth-child(2) {
                                width: 12.7%;
                            }

                            &:nth-child(3) {
                                width: 11%;
                            }

                            &:nth-child(4) {
                                width: 17%;
                            }
                        }
                    }
                }

                .renew_order {
                    &.inactive {
                        pointer-events: none;
                    }
                }

                .order_error {
                    display: table;
                    width: 100%;
                    color: red;
                    position: absolute;
                    left: 0;
                    top: 70px;
                }
            }
        }

        .wrapper_content_cmd_detail {
            width: 100%;
            max-width: 977px;
            margin: 0 auto;
            position: relative;
            font-family: $primaryFont;

            .order_detail_head {
                display: block;
                width: 50%;

                .cmd_date {
                    text-transform: capitalize;
                }
            }

            .go_back {
                font-size: 15px;
                line-height: 18px;

                span {
                    &::before {
                        content: url(../img/chevron-left.png);
                        transform: rotate(90deg);
                        height: 10px;
                        width: 10px;
                        position: relative;
                        padding: 0 5px;
                        color: $ctaColor;
                        font-family: $ctaFont;
                        font-size: 18px;
                    }
                }
            }

            .wrapper_infos_cmd {
                margin: 23px 0;

                .num_suivi {
                    a {
                        text-decoration: underline;
                    }
                }

                p {
                    line-height: 16.8px;
                    font-size: 14px;
                }

                .cmd_txt,
                .addr_title {
                    font-size: 24px;
                    margin: 15px 0;
                }
            }

            .wrapper_delivery_addr,
            .wrapper_delivery_date {
                margin: 34px 0 23px 0;

                p {
                    line-height: 17px;
                    font-size: 15px;
                }

                .cmd_txt,
                .addr_title,
                .date_title {
                    font-size: 24px;
                    margin: 16px 0;
                }
            }

            .wrapper_right {
                position: absolute;
                top: 0;
                right: 0;
                width: 45.6%;

                .facture-bloc {
                    font-family: $primaryFont;
                    font-size: 15px;
                    line-height: 18px;
                    margin-top: 15px;

                    a {
                        text-decoration: underline;
                    }
                }

                .resume_commande {
                    display: none;
                }
            }

            .wrap_total_commande {
                border: 2px solid $ctaColor;
                padding: 35px 35px 37px 34px;

                .titre {
                    font-family: $primaryFont;
                    text-transform: none;
                    font-size: 24px;
                    line-height: 28.8px;
                    margin-bottom: 20px;
                }

                .wrapper_total {
                    div {
                        padding: 8px 0;
                        font-family: $primaryFont;
                        font-size: 15px !important;

                        div {
                            padding: 0px 0;
                        }

                        &.clearfix {
                            &:last-child {
                                border-top: 2px solid $lightBorder;
                                margin: 15px 0 0 0;
                                padding: 20px 0 0 0;
                                font-family: $ctaFont;

                                label.montant_total {
                                    padding-top: 10px;
                                    vertical-align: middle;
                                    text-transform: lowercase;
                                }

                                div {
                                    padding: 0px 0;

                                    .price {
                                        font-size: 1.5rem;
                                        line-height: 1.8rem;
                                    }
                                }
                            }
                        }
                    }

                    .montants {
                        float: right;
                        font-size: 15px;
                    }
                }
            }

            .wrapper_right {
                .wrap_total_commande {
                    .product-link {
                        .cart_thumb_holder {
                            display: none;
                        }

                        .cart_detail_box {
                            .sub_product {
                                display: flex;
                                justify-content: space-between;
                            }
                        }
                    }
                }
            }
        }

        //COMMANDE
        .cmd_items {
            margin-top: 45px;

            .order_error {
                color: $red;
                margin-left: 10px;
                line-height: 2;
            }

            .product-link {
                display: flex;

                .cart_thumb_holder {
                    width: 26%;
                }

                .cart_product {
                    .holder_title {
                        display: flex;
                        width: 607px;

                        .sub_title {
                            width: 85.5%;
                            padding-top: 39px;
                        }

                        .wrapper_price {
                            .price_tag {
                                padding-top: 25px;
                            }
                        }
                    }
                }
            }
        }

        .table-order-command {
            padding: 0px 10px 0px 10px;
            width: 100%;
            display: block;

            thead {
                tr {
                    th {
                        vertical-align: middle;
                        text-align: center;
                        padding-top: 38px;
                        font-weight: 100;
                        text-transform: capitalize;

                        &:nth-child(4) {
                            padding-top: 39px;
                            padding-left: 80px;
                            text-align: left;
                        }
                    }
                }
            }

            tbody {
                tr {
                    border-bottom: 2px solid $tumbleweed;

                    td {
                        vertical-align: top;
                        text-align: center;

                        .sub_qte {
                            padding-top: 10px;
                        }

                        img {
                            width: 100%;
                            display: block;
                            margin-top: -20px;
                        }

                        &:nth-child(1) {
                            padding-top: 0px;
                            width: 12%;
                        }

                        &:nth-child(2) {
                            padding-top: 5px;
                            width: 39.5%;
                        }

                        &:nth-child(3) {
                            width: 20%;
                            padding-top: 17px;
                        }

                        &:nth-child(4) {
                            width: 30%;
                            padding: 26px 75px;
                            text-align: left;
                        }
                    }
                }
            }
        }

        .w-checkbox-input,
        .w-radio-input {
            display: flex;
            align-items: center;
            position: relative;
            width: auto;
            height: 2.5em;
            border: none;
        }

        .w-form-line.w-submit {
            max-width: 10.3rem;
        }

        .w-checkbox-input + .w-checkbox-input,
        .w-checkbox-input + .w-radio-input,
        .w-radio-input + .w-checkbox-input,
        .w-radio-input + .w-radio-input {
            margin-left: 1.9em;
        }

        //NEWSLETTER
        .content_newsletter {
            .w-checkbox-input {
                height: fit-content;
            }

            .w-form-line:first-of-type {
                margin: 26px 0 27px 0 !important;
            }

            .w-form-line.optin-select-line {
                margin-bottom: 15px !important;

                +.w-form-line.w-submit {
                    margin-top: 24px;
                }

                .w-checkbox-input {
                    .w-input-element {
                        &:checked + .w-input-label {
                            &:after {
                                margin-left: 11px;
                            }

                            &:before {
                                background: $ctaColor;
                                box-shadow: inset 0 0 0 0 $whiteColor;
                            }
                        }
                    }

                    .w-input-label {
                        padding: 0 15px 0 15px;

                        &:before {
                            content: '';
                            position: absolute;
                            display: block;
                            left: -3px;
                            top: 7px;
                            width: 26px;
                            height: 15px;
                            border-radius: 16px;
                            background: $mercury;
                            box-shadow: inset 0 0 0 0 $whiteColor;
                            border: 1px solid $lightGrey;
                            -webkit-transition: all 0.3s;
                            transition: all 0.3s;
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            display: block;
                            left: -2px;
                            top: 8px;
                            width: 13px;
                            height: 13px;
                            border-radius: 16px;
                            background: $whiteColor;
                            border: 1px solid $lightGrey;
                            transition: all 0.3s;
                        }

                        &:hover {
                            &::after {
                                box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
                            }
                        }

                        span {
                            &.multiline {
                                margin-left: 2.2rem;
                                font-family: $primaryFont;
                                color: $primaryColor;

                                strong {
                                    font-size: 15px;
                                    line-height: 30px;
                                    font-weight: 100;
                                }

                                span {
                                    font-size: 15px;
                                    line-height: 30px;
                                    margin-left: 0;

                                    &:last-of-type {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .update_success {
                padding: 0 0 20px 0;
                font-family: $ctaFont;
                font-size: 12px;
                text-transform: lowercase;
                color: $green;
            }

            .optin_fidelity {
                display: flex;
                padding: 15px;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 10px;
                background-color: #FAEFE9;
                margin-bottom: 20px;

                p {
                    font-size: 15px;
                    line-height: 1.2;
                }

                .w-checkbox-input {
                    height: auto;

                    .w-input-element+.w-input-label::before {
                        flex-shrink: 0;
                    }

                    span {
                        font-size: 15px;
                        font-family: $primaryFont;
                        color: $primaryColor;
                    }

                    a {
                        font-family: $primaryFont;
                        font-size: 15px;
                        color: $primaryColor;
                        text-decoration: underline;
                        margin-left: 1rem;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        //WHISHLIST
        .wishlist_title {
            font-size: 15px;
            font-family: $primaryFont;
            margin-bottom: 25px;
        }

        .nrArticlesSelected {
            .bloc_price {
                font-size: 24px;
                font-family: $primaryFont;
                text-transform: lowercase;
            }

            .txt {
                font-family: $primaryFont;
                text-transform: initial;
                font-size: 24px;
            }
        }

        .wrapper_wishlist {
            .wish_liste_product {
                display: flex;
                flex-wrap: wrap;
                position: relative;
                margin-top: 18px;

                .wrapper_product {
                    width: 92%;
                    margin: 3%;
                    position: relative;
                    height: 390px;
                }

                .close {
                    width: 12px;
                    height: 12px;
                    background-image: url('../svg/icon_close.svg');
                    position: absolute;
                    top: 11px;
                    right: 9px;
                }

                .product_ctn {
                    position: relative;
                    text-align: center;
                    width: 33%;
                    margin-bottom: 0px;
                    margin-top: 16px;

                    .wrapper_details,
                    .subtitle,
                    .cta-modify,
                    .report,
                    .cta_add_to_basket {
                        display: none;
                    }

                    .wrapper_img {
                        img {
                            width: 100%;
                        }
                    }

                    .wrap_txt {
                        .title {
                            font-family: $ctaFont;
                            font-size: 14px;
                            letter-spacing: 0.2em;
                            line-height: 16.8px;
                            text-transform: lowercase;
                            margin: 15px 0;
                            padding: 0 20px;
                        }

                        .wrapper_price {
                            margin-top: 20px;

                            .price_tag {
                                font-family: $primaryFont;
                                font-size: 24px;
                                line-height: 28.8px;
                                letter-spacing: 0.1em;
                                text-align: center;

                                &.old_price {
                                    display: none;
                                }
                            }
                        }
                    }

                    &.checked,
                    &:hover {
                        cursor: pointer;

                        .cta-modify {
                            display: none;
                        }

                        .price_tag {
                            display: block;
                        }

                        &::before {
                            content: '';
                            background: linear-gradient(90deg,
                                    rgba(196, 130, 104, 1) 0%,
                                    rgba(253, 192, 164, 1) 49%,
                                    rgba(196, 130, 104, 1) 100%);
                            position: absolute;
                            height: 410px;
                            width: 96%;
                            z-index: -1;
                            opacity: 1;
                            left: 0;
                        }

                        .product_check {
                            z-index: 2;
                            background-color: $whiteColor;
                        }

                        .wrapper_product {
                            z-index: 2;
                            background-color: $whiteColor;
                            position: relative;
                        }
                    }

                    &.out_of_stock {
                        &:hover {
                            cursor: pointer;

                            .cta-modify {
                                display: none;
                            }

                            .price_tag {
                                display: block;
                            }

                            &::before {
                                content: '';
                                background: linear-gradient(90deg,
                                        #929292 0,
                                        #cdcdcd 50%,
                                        #929292 100%);
                                position: absolute;
                                height: 99%;
                                width: 98%;
                                z-index: -1;
                                transition: all ease-in-out 0.35s;
                            }

                            .product_check {
                                z-index: 2;
                                background-color: $whiteColor;
                            }

                            .wrapper_product {
                                z-index: 2;
                                background-color: $whiteColor;
                                position: relative;
                                margin-bottom: 15px;
                            }
                        }
                    }
                }
            }

            .product_check {
                input[type='checkbox'] {
                    /* Add if not using autoprefixer */
                    -webkit-appearance: none;
                    appearance: none;
                    /* For iOS < 15 to remove gradient background */
                    background-color: $whiteColor;
                    /* Not removed via appearance */
                    margin: 0;
                    font: inherit;
                    color: $ctaColor;
                    width: 1em;
                    height: 1em;
                    border: 0.15em solid $ctaColor;
                    transform: translateY(-0.075em);
                    display: grid;
                    place-content: center;
                    margin: auto;
                    top: -2px;
                    position: absolute;
                    z-index: 4;
                    left: 46%;
                }

                input[type='checkbox']::before {
                    content: '';
                    width: 0.5em;
                    height: 0.5em;
                    transform: scale(0);
                    transition: 120ms transform ease-in-out;
                    box-shadow: inset 1em 1em $ctaColor;
                }

                input[type='checkbox']:checked::before {
                    transform: scale(1);
                }
            }
        }

        .wrapper_btn_add {
            display: flex;
            margin-top: 35px;

            .form_submit {
                margin-right: 30px;

                .secondary {
                    padding: 16px 38px;
                }
            }
        }

        // MESSAGE
        .content_messages {
            .intro {
                font-family: $primaryFont;
                font-size: 0.9375rem;
                line-height: 18px;
            }

            .form_submit {
                width: auto;
                display: inline-block;

                .button {
                    margin: 15px 0 25px 0;
                    padding: 0 3em;
                }
            }

            .table_mes_messages {
                margin-top: 10px;
                width: 100%;

                thead {
                    td {
                        background-color: $ctaColor;
                        color: $whiteColor;
                        font-family: $ctaFont;
                        font-size: 14px;
                        line-height: 16.8px;
                        padding: 11px 21px;
                        letter-spacing: 0.2em;
                        text-align: left;
                        text-transform: lowercase;

                        &:nth-child(2) {
                            padding: 6px 35px 12px 54px;
                            width: 28%;
                        }

                        &:nth-child(3) {
                            padding: 11px 20px;
                        }
                    }
                }

                tbody {
                    border-bottom: 1px solid $lightBorder;

                    &:last-child() {
                        border: 0px solid $primaryColor;
                    }

                    tr.read {
                        td {
                            color: $ctaColor;
                        }
                    }

                    td {
                        text-transform: none;
                        background-color: $whiteColor;
                        color: $primaryColor;
                        font-family: $primaryFont;
                        font-size: 15px;
                        line-height: 16.8px;
                        padding: 22px 20px 0 20px;
                        text-align: left;

                        &.open {
                            text-align: right;
                            text-decoration: underline;
                            cursor: pointer;
                            padding: 0;
                            width: 60px;
                        }

                        &.wrapper_message_detail {
                            padding: 0px 0;
                        }

                        .btn_ouvrir_message {
                            text-transform: lowercase;
                        }

                        .enveloppe {
                            text-transform: none;
                        }

                        &:nth-child(2) {
                            padding: 6px 35px 12px 48px;
                            width: 28%;
                        }

                        &:nth-child(3) {
                            padding: 11px 20px;
                        }
                    }
                }

                .from,
                .objet,
                .de {
                    display: none;

                    &.date {
                        display: inline-block;
                        padding: 5px;
                        color: $ctaColor;
                    }
                }

                .message_detail_pj {
                    margin-top: 10px;
                    margin-left: 18px;
                    margin-bottom: 5px;
                    text-transform: none;

                    .f_left {
                        display: inline-block;
                        margin-left: 0 !important;
                    }
                }

                .details_msg_content {
                    float: right;
                    display: block;
                    width: 75%;
                    margin-bottom: 20px;

                    .message_details_holder {
                        background-color: $ctaColor;
                        padding: 20px;
                        border-radius: 20px;
                        color: $whiteColor;
                        margin-bottom: 8px;
                    }

                    .infos_message {
                        color: $ctaColor;
                        width: 97%;
                        margin: auto;
                    }

                    &.class_addline {
                        float: left;
                        display: block;

                        .message_details_holder {
                            background-color: $lightBorder;
                            padding: 20px;
                            border-radius: 20px;
                            color: $primaryColor;
                        }
                    }
                }
            }

            .w-reply-form {
                padding: 0;
            }

            .w-textarea {
                textarea {
                    padding: 14px !important;
                    height: 8em !important;
                    border-top: 1px solid $backgroundColor  !important;
                    border-bottom: 1px solid $backgroundColor  !important;
                    border-left: 0px solid $backgroundColor  !important;
                    border-right: 0px solid $backgroundColor  !important;
                    padding: 18px 20px !important;

                    &::placeholder {
                        font-style: normal;
                        font-family: $primaryFont;
                    }
                }

                .w-input-label {
                    font-family: $primaryFont;
                    font-size: 15px;

                    span {
                        top: 4px;
                        position: relative;
                        left: 6px;
                        color: $secondaryColor;
                        text-transform: capitalize;
                    }
                }
            }

            .file-list {
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .filepj1 {
                display: none;
            }

            .ajout_pj_label {
                font-size: 11px;
                font-family: $ctaFont;
                text-transform: lowercase;
                letter-spacing: 0.2em;
                border-bottom: 1px solid $primaryColor;
                padding-bottom: 3px;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .wrapper_moncompte {
        .wrapper_content_password {
            .w-form-line.w-submit {
                max-width: 22rem;
            }
        }
    }
}

// Addresses
.wrapper_mes_adresses {
    .conteneur_adresse {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 2rem;

        .order_livraison, .order_facturation{
            .modlinks_wrapper{
                .modlink.modify2:nth-child(2){
                    display:none;
                }
            }
        }

        .order_livraison{
            .modlinks_wrapper{
                .modlink.modify2:nth-child(3){
                    display:none;
                }
            }
        }
    }

    .w-address-form{
        display: flex;
        flex-direction: column;
        opacity: 0;
        transition: opacity ease-in-out 350ms;

        .form-element-wrapper{
            order:3;
        }

        .img_error {
            position: absolute;
            right: 5px;
            top: 50%;
            bottom: 0;
            transform: translateY(-50%);
        }
    }

    .w-form-line {

        .noaddr {
            right: 0.5rem;
        }
        &.addrPhone{
            order: 4;
        }
        &.addrAutoComplete{
            order: 1;
        }
        &.addrType{
            order: 5;
            position: relative;
            top: -0.625rem;
        }
        &.addrSubmit{
            order: 6;
        }
    }

    .addrFullDisplay{
        order: 2;
        display: block!important;
        &.elem_global {
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    .valid_form {
        position: absolute;
        top: 15px;
        right: 10px;
    }

    .erreur_tab {
        color: $red;
    }

    .w-input-group {
        margin-bottom: 0rem;

    }

    .w-group-label {
        font-size: 1.5rem;
        font-family: $primaryFont;
        margin-bottom: 15px;
        padding-top: 0px;
    }

    .adresse {
        margin: 42px 50px 30px 0;
        order:2;
        &.order_facturation{
            order:1;
        }
    }

    .modlinks_wrapper {
        display: flex;
        flex-direction: column;

        .modlink {
            width: fit-content;
            margin-top: 15px;
            display: inline-block;
            border-bottom: 1px solid $primaryColor;

            &:hover {
                border-bottom: 1px solid $whiteColor;
            }

            span {
                text-transform: lowercase;
            }
        }
    }

    .addr_titre {
        color: $primaryColor;
        font-size: 24px;
        font-family: $primaryFont;
        display: inline-block;
        margin-bottom: 10px;
    }

    .addr_type {
        color: $ctaColor;
        font-size: 24px;
        font-family: $primaryFont;
        display: inline-block;
        margin-bottom: 10px;
    }

    .infos p {
        letter-spacing: 0.5px;
        line-height: 18px;
    }

    .form_submit {
        width: fit-content;
        margin-bottom: 2rem;
        .button {
            width: fit-content;
            padding: 0 3rem;

            &.secondary{
                background-color: $ctaColor;
                color: $whiteColor;
                &:hover{
                    background-color: $whiteColor;
                    color: $ctaColor;
                }
            }
        }
    }

     //update w-login-form &
    .w-login-form, .w-address-form, .w-newuser-form{

        .form-element-wrapper{
            display: flex;
            .has-civility, .addrCivility{
                width: 50%;
                margin-right: 1rem;
            }
            .w-form-line:last-of-type{
                margin-bottom: 1.2rem;
            }
        }

        .elem_global{
            margin-top: 0;
        }

    }

    .edit_adresse{
        .w-newuser-form{
            .delivery, .bill{
                fieldset{
                    legend{
                        display: block!important;
                        &:nth-child(1){
                            display: none!important;
                        }
                    }
                }
            }
            fieldset{
                + .delivery, + .bill{
                    .hide{
                        display: none!important;
                    }
                }
            }
        }
    }

    #favorite_store {
        margin-top: 2rem;
        .adresse {
            border: 3px solid $ctaColor;
            width: fit-content;
            padding: 2rem;

            .address_title {
                font-size: 24px;
                font-family: $primaryFont;
                display: inline-block;
                margin-bottom: 10px;
            }

            .change_store {
                text-transform: lowercase;
                text-decoration: underline;
                margin-top: 8px;
            }
        }
    }
}

.wrapper_moncompte_main {
    .renew_order {
        width: 288px;
        margin: 10px 10px 0 auto;
    }
}

/**
 * Password forget/Create
 */
.wrapper_content_password {
    .title_infos {
        font-size: 60px;
        font-style: italic;
        font-weight: 400;
        margin: 2rem 0 1rem;
    }
}

body.de {
    .wrapper_moncompte {
        .wrapper_mes_commandes {
            .wrapper_menu_tab {
                ul li span {
                    font-size: 11px;
                }
                .date_select select {
                    font-size: 11px;
                }
                .date_select::after {
                    top: 14px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1025px) {
    body {
        .wrapper_moncompte {
            .content_newsletter {
                .w-email-input input {
                    width: 100% !important;
                    height: 40px !important;
                }
            }

            .wrapper_menu_compte {
                gap: 0;

                div {
                    margin-right: 1.35rem;

                    &.fidelity {
                        margin-right: 0;
                    }
                }
            }

            .content_messages {
                .table_mes_messages {
                    width: 99%;
                }
            }
        }
    }
}

@media only screen and (max-width: 1400px) {
    body {
        .wrapper_moncompte {
            .moncompte_myinfos_img_holder {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    body {
        .wrapper_moncompte {
            width: 100%;

            .content_messages {
                .table_mes_messages {
                    width: 100%;
                }
            }

            .wrapper_mes_commandes {
                width: 100%;
            }

            .moncompte_myinfos_img_holder {
                display: none;
            }
        }

        &.de {
            .wrapper_menu_compte div {
                font-size: 12px;
            }

            .wrapper_moncompte {
                .wrapper_mes_commandes {
                    .wrapper_menu_tab {
                        ul li span {
                            font-size: 10px !important;
                        }
                        .date_select select {
                            font-size: 10px !important;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-device-width: 1480px) and (orientation: landscape) {
    .breadcrumbs {
        max-width: $wrapperSmall;
    }
}

.w-textarea.w-nonempty {
    textarea.w-input-element + .w-input-label {
        display: none;
    }
}

body.customer {
    .w-form-line.last-is-hidden {
        align-items: flex-start;
    }

    @media only screen and (max-width: 1400px) {
        .newlogin_page {
            align-items: flex-start;
        }
    }

    @media only screen and (max-width: 1280px) {
        &.cart {
            padding: 0 20px;
        }
    }

    #adresseForm {
        .w-tel-input.w-has-error #iconValidtelephone {
            display: none;
        }

        .errormsgadd {
            position: relative;
            top: 0.1rem;
        }
    }

    .w-info-form.w-login-form {
        .w-tel-input.w-has-error #iconValidclientTel {
            display: none;
        }

        .errormsgadd {
            position: relative;
            top: 0.11rem;
        }
    }

    .wrapper_content_mes_infos {
        .update_success {
            opacity: 0;
        }
    }
}
