.cadeau_entreprise {
    #site_head_wrap.sticky .head_top {
        box-shadow: none;
    }

    .mobile {
        display: none !important;
    }

    #ariane_home,
    .see_more {
        display: none;
    }

    .desktop {
        display: block;
    }

    #breadcrumbs {
        width: 99%;
        max-width: 1730px;
        margin: 0 auto;
        padding: 0 0.9rem;

        .breadcrumbs {
            margin-bottom: 7px;
        }
    }

    .cta_nos_contacts {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $whiteColor;
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;
        border-top: solid 1px $ctaColor;
        padding: 22px 0;
        position: fixed;
        top: -100%;
        left: 0;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
        z-index: 10;
        transition: all 0.5s ease-in-out;

        .btn_container {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
            max-width: 650px;
            width: 100%;
            margin: 0 auto;

            .button {
                background-color: $ctaColor;
                color: $white;

                &:hover {
                    background-color: $white;
                    color: $ctaColor;
                }
            }
        }
    }

    .background_img {
        background-image: url('../img/satellite/bloc_bandeau_b2b.jpg');
        background-size: cover;
        background-position: center center;
        height: 360px;
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
        position: relative;

        .back_shad {
            background-color: rgba(0, 0, 0, .2);
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    .wrapper_content_cadeau {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;

        h1 {
            font-family: $primaryFont;
            font-size: 60px;
            font-style: italic;
            font-weight: 400;
            line-height: 72px;
            margin-bottom: 10px;
        }

        h2 {
            font-family: $primaryFont;
            font-size: 42px;
            font-style: italic;
            font-weight: 400;
        }

        h3 {
            font-family: $primaryFont;
            font-size: 35px;
            font-style: italic;
            font-weight: 400;
        }

        .cta_devis,
        .cta_catalogue {
            font-size: 13px;
            line-height: 18px;
            text-align: center;
            letter-spacing: 0.25em;
            background-color: $ctaColor;
            color: $white;
            height: auto;
            width: auto;
            display: inline-block;
            padding: 15px 42px;

            &:hover {
                background-color: transparent;
                color: $ctaColor;
            }
        }

        .cta {
            font-size: 13px;
            line-height: 18px;
            text-align: center;
            letter-spacing: 0.25em;
            width: auto;
            display: block;
            padding: 13px 37px;
            height: auto;
        }

        .intro {
            width: 100%;
            max-width: 1230px;
            margin: 72px auto 0 auto;
            text-align: center;

            p {
                font-family: $primaryFont;
                font-size: 24px;
                font-weight: 400;
                line-height: 28px;

                strong {
                    font-weight: 700;
                }
            }
        }

        .services {
            margin: 85px auto 0 auto;
            width: 100%;
            max-width: 1335px;

            h2 {
                margin-bottom: 30px;
                text-align: center;
            }

            .block_services {
                display: flex;
                align-items: center;
                gap: 11%;
                margin-bottom: 80px;
                align-content: center;
                justify-content: flex-start;

                .block_services_img {
                    img {
                        width: 100%;
                    }
                }

                .block_services_txt {
                    width: 47.5%;
                    position: relative;
                    top: 6px;
                }

                h2 {
                    text-align: left;
                    margin-bottom: 15px;
                }

                p {
                    font-family: $primaryFont;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 18px;

                    strong {
                        font-weight: 700;
                    }
                }

                &.block_services_5 a {
                    display: block;
                    width: fit-content;
                    text-decoration: underline;

                    &:not(:last-of-type) {
                        margin-bottom: 16px;
                    }
                }
            }
        }

        .catalogue {
            margin: 0px auto 0 auto;
            width: 100%;
            max-width: 1430px;

            h2 {
                margin-bottom: 37px;
                text-align: center;
                margin-top: 5px;
            }

            .content_catalogue {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                gap: 3.5%;
                margin-bottom: 85px;

                .bloc_catalogue {
                    display: flex;
                    justify-content: center;
                    flex-wrap: nowrap;
                    flex-direction: column;
                    align-items: center;
                    width: 25%;
                }

                .bloc_catalogue_title {
                    height: 250px;

                    h3 {
                        text-align: center;
                        line-height: 40px;
                        padding: 15px 0px 20px 0;
                        display: flex;
                        align-items: center;
                        align-content: center;
                        justify-content: center;
                        height: 150px;
                        font-size: clamp(30px, fs-vw(35, 1920), 35px);
                    }
                }

                .bloc_catalogue_img {
                    height: 230px;

                    img {
                        width: 100%;
                    }
                }

                .cta {
                    max-width: 230px;
                    margin: auto;

                    &.conseiller {
                        padding: 13px 37px;
                        max-width: 260px;
                    }
                }

                &.first_catalog {
                    margin-bottom: 0;
                }
            }
        }

        .offres {
            margin: 0px auto 85px auto;
            width: 100%;
            max-width: 1675px;

            h2 {
                margin-bottom: 35px;
                text-align: center;
            }

            .content_offres {
                display: flex;
                gap: 1.5%;
                color: $whiteColor;
                justify-content: center;

                .bloc_offres {
                    height: 340px;
                    width: 100%;
                    max-width: 400px;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    background-repeat: no-repeat;

                    &.bloc_offres_1 {
                        background-image: url('../img/satellite/offres_annee.jpg');
                    }

                    &.bloc_offres_2 {
                        background-image: url('../img/satellite/offres_personalisation.jpg');
                    }

                    &.bloc_offres_3 {
                        background-image: url('../img/satellite/offres_paques.jpg');
                    }

                    &.bloc_offres_4 {
                        background-image: url('../img/satellite/offres_format.jpg');
                    }

                    .cta {
                        color: $whiteColor;
                        font-size: 12px;
                        background-color: transparent;
                        letter-spacing: 3.8px;
                        border: 1px solid $whiteColor;
                        box-shadow: inset 0 0 0 2px transparent;
                        padding: 15px 42px;
                        margin-top: 25px;

                        &:hover {
                            border: 1px solid $ctaColor;
                            box-shadow: inset 0 0 0 2px $whiteColor;
                            background-color: $ctaColor;
                        }
                    }
                }
            }
        }

        .contact {
            margin: 0px auto 85px auto;
            width: 100%;
            max-width: 1366px;

            h2 {
                margin-bottom: 33px;
                text-align: center;
            }

            .or {
                margin-top: 10px;
            }

            .content_contact {
                display: flex;
                gap: 60px;

                .contact_conseil {
                    text-align: center;
                    border: 2px solid $ctaColor ;
                    width: 46.5%;
                    padding: 45px 0;
                    height: 663px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    h3 {
                        font-family: $primaryFont;
                        font-size: 35px;
                        font-style: italic;
                        font-weight: 400;
                        margin-bottom: 13px;
                    }

                    .sep-general {
                        width: 40px;
                        height: 1px;
                        background-color: $ctaColor;
                        display: inline-block;
                        margin: 35px 0 25px 0;
                    }

                    .sep {
                        width: 40px;
                        height: 1px;
                        background-color: $ctaColor;
                        display: inline-block;
                        margin: 24px 0 24px 0;
                    }

                    .mail_general {
                        color: $ctaColor;
                        text-align: center;
                        font-family: $ctaFont;
                        font-size: 16px;
                        font-weight: 850;
                        letter-spacing: 3.2px;
                        text-decoration: underline;
                        text-underline-offset: 10px;
                        display: block;
                    }

                    .contact_info {
                        p {
                            font-size: 15px;
                            margin-bottom: 7px;
                        }

                        .mail {
                            font-size: 12px;
                            letter-spacing: 2.4px;
                            margin-bottom: 14px;
                            display: block;
                            color: $ctaColor;
                            text-align: center;
                            font-family: $ctaFont;
                            text-decoration: underline;
                            text-underline-offset: 8px;
                        }

                        .tel {
                            font-family: $primaryFont;
                            color: $primaryColor;
                            font-size: 13px;
                            font-weight: 400;
                            text-decoration-line: underline;
                            text-underline-offset: initial;
                            display: block;
                        }
                    }
                }
            }

            .form_contact {
                width: 49%;

                h3 {
                    font-family: $primaryFont;
                    font-size: 35px;
                    font-style: italic;
                    font-weight: 400;
                    margin-bottom: 32px;
                }

                .errormsgadd {
                    display: none;
                }

                .wrapperErrors {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;
                    color: $primaryColor;
                    width: 100%;

                    p {
                        margin-bottom: 20px;
                    }

                    .red {
                        color: $red;
                    }

                    .valid {
                        color: $green;
                    }
                }
            }
        }

        .confiance {
            margin: 0px auto 68px auto;
            width: 100%;
            max-width: 1365px;

            h2 {
                margin-bottom: 60px;
                text-align: center;
            }

            .bloc_logo {
                display: flex;
                gap: 110px;

                @media (max-width: 1200px) {
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    gap: 60px 135px;
                }

                @media (max-width: 850px) {
                    gap: 60px 80px;
                }
            }

            .cta_devis {
                display: block;
                width: auto;
                max-width: 292px;
                margin: 45px auto 0 auto;

                &:hover {
                    background-color: $ctaColor;
                    color: $whiteColor;
                }
            }
        }
    }

    #shad {
        &.for_lb_contacts {
            z-index: 100;
        }
    }
}

/* Lightbox ~ Tous nos contacts */
#lightbox_nos_contacts {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 678px;
    height: 684px;
    padding: 37px 90px 70px;
    z-index: 101;

    &.actif {
        display: flex !important;
    }

    .title {
        font-size: 2.5rem;
    }

    .close_pop {
        display: flex;
        align-items: center;
        justify-content: center;
        background: url('../svg/icon_close.svg');
        width: 12px;
        height: 12px;
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .wrap_contacts {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;

        .contact {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            .contact_title {
                font-family: $primaryFont;
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
                text-align: start;
                color: $primaryColor;
                margin-bottom: 8px;
            }

            .nom,
            .email,
            .tel {
                font-family: $primaryFont;
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
                color: $primaryColor;
            }

            .email {
                color: $ctaColor;
                text-decoration: underline;
            }
        }
    }
}

@media screen and (max-width: 1024px),
(device-width: 768px),
(device-width: 820px) {
    body.cadeau_entreprise {
        .bloc_catalogue {
            flex-wrap: wrap;
            padding: 0 15px;

            .catalogue {
                .cat_title {
                    font-size: 30px !important;
                }
            }
        }
    }
}

// Carte Cadeau
body.giftcard_index {
    .pageGiftCard {
        margin: 0 0rem 6rem;
        display: flex;
        width: 100%;
        max-width: 1920px;
        min-width: 990px;
        align-items: flex-start;
        font-family: $primaryFont;
        line-height: 1;
        flex-direction: row;
        align-items: center;
        opacity: 0;

        /* Page header content */
        .pageGiftCard__header {
            display: flex;
            flex-direction: column;

            .headerTitles {
                display: flex;
                flex-direction: column;
                margin-bottom: 0.3rem;

                &.is-hidden {
                    opacity: 1;
                    z-index: 1;
                    display: none;
                }

                span {
                    text-align: left;
                    font-size: 14px;
                    font-family: $ctaFont;
                    letter-spacing: 2px;
                    order: 2;
                    color: $primaryColor;
                    font-weight: 100;

                    @media screen and (max-width: 1030px) {
                        font-size: 0.9rem;
                        margin-left: -1%;
                    }
                }
            }

            h3 {
                text-align: left;
                font-size: 11px;
                font-weight: 600;
                font-family: $ctaFont;
                line-height: 16.8px;
                letter-spacing: 0.21rem;
                text-transform: uppercase;
                order: 2;

                @media screen and (max-width: 1030px) {
                    font-size: 0.55rem;
                    margin-left: -1%;
                }
            }

            h2 {
                text-align: left;
                font-size: 60px;
                font-weight: 400;
                font-family: $primaryFont;
                line-height: 72px;
                font-style: italic;
                order: 1;

                @media screen and (max-width: 1030px) {
                    font-size: 2.5rem;
                    margin-left: -1%;
                }
            }

            #showAmount {
                display: none;
            }
        }

        /* Page main content */
        .pageGiftCard__main {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 43.5rem;
            margin: 2% 11% 6.25% auto;
            justify-content: center;

            @media screen and (max-width: 1030px) {
                margin: 2% 7% 6.25% -1%;
            }

            &.pageGiftCard__main--amountinfos {
                height: fit-content;
                width: auto;

                @include queriesWshop(1030px, max) {
                    width: 35%;
                }

                .return {
                    display: block !important;
                    margin-top: 1.3rem;

                    .showAmountBtn {
                        background: $whiteColor;
                        color: $ctaColor;
                        border: 1px solid $ctaColor;
                        width: 10rem;

                        &:hover {
                            background: $ctaColor;
                            color: $whiteColor;
                        }
                    }
                }

                h3 {
                    margin-bottom: 1rem;
                }

                small {
                    margin: .5rem 0;
                }

                &.logged {
                    .return {
                        display: block !important;
                    }

                    h3 {
                        font-size: 60px;
                        font-weight: 400;
                        margin-bottom: .3rem;
                        font-style: italic;
                        line-height: 72px;
                        font-family: $primaryFont;
                        letter-spacing: 0.13rem;
                        order: 0;
                        text-transform: none;

                        @media screen and (max-width: 1030px) {
                            font-size: 2.5rem;
                            width: 36rem;
                        }
                    }

                    small {
                        margin-top: 8px;
                        letter-spacing: 0.0255rem;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 18px;
                        font-family: $primaryFont;
                        order: 0;

                        @media screen and (max-width: 1030px) {
                            width: 43rem;
                        }
                    }

                    form {
                        margin-top: 0.5rem;
                    }

                    .giftcard_amount {
                        font-weight: 400;
                        font-size: 24px;
                        margin-bottom: 0.9rem;
                        text-transform: none;
                        line-height: 28.8px;
                        font-family: $primaryFont;
                        letter-spacing: 0.055rem;

                        strong {
                            font-weight: normal;
                        }
                    }

                    .giftcard_validity_fr {
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 18px;
                        display: block !important;
                    }
                }
            }

            h1 {
                font-size: 15px;
                font-weight: 400;
                line-height: 18px;
                margin-bottom: .125rem;
                font-family: $primaryFont;
                letter-spacing: 0.1px;

                @media screen and (max-width: 1700px) {
                    font-size: 15px;
                    padding-right: 5rem;
                }
            }

            .pageGiftCard__bloc {
                display: flex;
                flex-direction: column;

                h3 {
                    display: block;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;
                    font-family: $primaryFont;
                    letter-spacing: 0.031rem;
                    order: 6;
                    text-transform: none;
                    margin-top: 6.9rem;
                    width: 73%;
                }

                h1 {
                    order: 3;
                    width: 47rem;
                }
            }

            h2 {
                display: none;
            }

            small {
                font-size: 15px;
                font-weight: 400;
                line-height: 18px;
                margin-bottom: .125rem;
                font-family: $primaryFont;
                order: 4;

                strong {
                    font-weight: 700;
                    line-height: 2.5;
                }
            }

            /** First main - Gift card infos details **/
            .infosList {
                display: none;
            }

            .cgvLink:first-of-type {
                font-size: .938rem;
                font-weight: 400;
                margin-top: 1rem;
                text-decoration: underline;
                display: block !important;
                text-underline-offset: 0.3rem;
                color: $primaryColor;
                order: 5;
            }

            .cgvGift {
                display: none;
            }

            .btn_container {
                height: 3rem;
                margin-right: 1.5rem;
                width: fit-content;
            }

            .giftCard_btn {
                display: flex;
                position: absolute;
                top: 10.9rem;
                flex-direction: row-reverse;

                @media screen and (max-width: 768px) {
                    flex-wrap: wrap;
                }

                #showAmount {
                    display: block !important;

                    .button {
                        min-width: 24.2rem;
                        background: $white;
                        color: $ctaColor;
                        border: 1px solid $ctaColor;

                        @media screen and (max-width: 1700px) {
                            min-width: 20rem;
                        }

                        &:hover {
                            background: $ctaColor !important;
                            color: $white;
                            border: 1px solid $ctaColor;
                        }
                    }
                }

                #openOrderGiftCard {
                    .button {
                        width: 22.6rem;
                        background: $white;
                        color: $ctaColor;

                        @media screen and (max-width: 1700px) {
                            width: 20rem;
                        }

                        &:hover {
                            background: $ctaColor !important;
                            color: $white;
                            border: 1px solid $ctaColor;
                        }
                    }

                    .offerCardBtn {
                        background: $ctaColor !important;
                        color: $white;
                        border: 1px solid $ctaColor;

                        &:hover {
                            background: $white !important;
                            color: $ctaColor;
                        }
                    }
                }
            }

            /** Second main - Gift card form - Amount details **/
            .w-giftcard-form {
                .giftcard_desc {
                    display: none;
                }

                .giftcard_amount {
                    font-size: .8rem;
                    margin-top: 1.875rem;
                    text-transform: uppercase;
                    line-height: 1.3;
                }

                .w-form-line {
                    position: relative;
                    margin-bottom: 0 !important;
                    display: flex;
                    gap: 22px;

                    #cardCode {
                        width: 580px;
                        border: 1px solid $lighterGrey;
                        box-shadow: none;

                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }
                    }

                    .w-input {
                        border-radius: 0;
                        border-color: $lighterGrey;

                        &:focus {
                            outline: none;
                        }
                    }

                    .w-submit {
                        .w-submit-button {
                            width: 6rem;
                            background-color: $ctaColor;
                            font-family: $ctaFont;
                            font-size: 15px;
                            text-transform: lowercase;
                            padding: 0;
                            margin: 0;
                            color: $whiteColor;
                            box-shadow: inset 0 0 0 2px $whiteColor;
                        }
                    }
                }
            }
        }

        /*** General btn style ***/
        .btn_container button {
            width: fit-content;
            padding: 0 2rem;
        }

        /*** Show/hide class ***/
        .is-hidden {
            display: none;

            .pageGiftCard__bloc {
                h3 {
                    display: block !important;
                }
            }
        }

        .text-besoin-aide {
            display: block;
            font-size: 14px !important;
            line-height: 18px !important;
            letter-spacing: .06rem !important;
            text-align: left;
            text-transform: none;
            font-style: normal !important;
            margin-top: 2rem;
            width: 66%;

            &.is-hidden {
                display: none;
            }
        }

        .pageGiftCard__asideImg {
            margin-right: 4.2rem;

            @media screen and (max-width: 1030px) {
                margin-right: 1.2rem;
            }

            img {
                width: 100%;
            }
        }
    }

    &.en {
        #step3 {
            li p {
                &#js-message {
                    margin-left: 1.3rem;
                }

                &#js-signature {
                    margin-left: 7.4rem;
                }

                &#js-balance {
                    margin-left: 5.6rem;
                }

                &#js-name {
                    margin-left: 1.5rem;
                }
            }
        }

        .pageGiftCard .pageGiftCard__main {
            .giftCard_btn {
                top: 9.8rem;
            }

            .text-besoin-aide {
                width: 61%;
            }
        }
    }

    &.nl {
        #step3 {
            li p {
                &#js-message {
                    margin-left: 3.3rem;
                }

                &#js-signature {
                    margin-left: 8.5rem;
                }

                &#js-balance {
                    margin-left: 6rem;
                }

                &#js-name {
                    margin-left: 1.4rem;
                }
            }
        }

        .pageGiftCard .pageGiftCard__main {
            .giftCard_btn {
                top: 9.7rem;
            }
        }
    }

    &.de {
        .headerTitles {
            h2 {
                margin: 0 0 0.5rem -0.2rem;
            }
        }

        .pageGiftCard__main {
            .text-besoin-aide {
                width: 60%;
            }

            &.pageGiftCard__main--infos {
                margin: 2% 7% 6.25% 0;
            }
        }

        #openOrderGiftCard {
            .button {
                &.offerCardBtn {
                    span {
                        white-space: normal;
                    }
                }
            }
        }

        #step3 {
            li p {
                &#js-message {
                    margin-left: .5rem;
                }

                &#js-signature {
                    margin-left: 8.7rem;
                }

                &#js-balance {
                    margin-left: 6.4rem;
                }

                &#js-name {
                    margin-left: 2rem;
                }
            }
        }
    }

    .shad.dispatch_shad {
        z-index: 100;
    }
}

.lightboxOrderGiftCard {
    display: none;
    width: 800px;
    display: none;
    position: fixed;
    z-index: 100;
    top: 54%;
    left: 49%;
    background: $whiteColor;
    z-index: 101 !important;

    &.actif {
        display: block;
    }

    .close {
        position: absolute;
        top: 9px;
        right: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url('../svg/icon_close.svg');
        width: 12px;
        height: 12px;
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .min_amount_gift {
        display: block !important;
        text-align: left;
        margin-top: .5rem;
        font-size: .8rem;
        color: $runefangSteel;
        font-style: italic;
    }
}

.orderGiftCardForm {
    width: 100%;
    box-sizing: border-box;
    padding: 0.75rem 0;
    z-index: 104;

    h2 {
        text-align: center;
        font-weight: 400;
        font-size: 15px;
        text-transform: none;
        margin-top: 25px;
        line-height: 18px;
        flex-direction: column;
        align-items: center;
        align-content: center;
        font-family: $primaryFont;
        position: relative;
        display: inline-block;
    }

    h2::after {
        content: '';
        height: 2px;
        width: 100px;
        background: $lightBorder;
        position: absolute;
        left: 50%;
        top: 27px;
        transform: translate(-50%);
    }

    .selectBalanceLabel {
        text-align: center;
        font-weight: 900;
        font-size: 1.3rem;
        justify-content: center;
        text-transform: uppercase;
    }

    h3,
    h4 {
        font-size: .875rem;
        text-transform: uppercase;
    }

    h3 {
        font-weight: 500;
        text-align: center;
    }

    h4 {
        font-weight: 200;
        display: none;
    }

    .step {
        background-color: $whiteColor;
        display: none;
        grid-column: 1;
        grid-row: 1;

        .giftDetails {
            width: 98%;
            height: 100%;
            min-height: 366px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            .infos {
                width: 100%;

                &.step1_infos {
                    display: flex;
                    flex-direction: column-reverse;
                    margin: 0 auto;

                    .first {
                        color: $grey;
                        text-align: center;
                        font-weight: 400;
                        margin-top: .5rem;
                    }

                    #sendMethods {
                        .sendMethodsOption {
                            margin: auto 1rem;
                            padding: 0.5rem 1rem 0;
                            text-align: center;
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            &.selected {
                                border: 1px solid $darklategray;
                            }

                            &.inputErr {
                                border: 1px solid $red;
                            }

                            input {
                                display: none;
                            }
                        }

                        #infos_desc_mail {
                            display: flex;
                            flex-direction: column;
                            cursor: default;

                            p {
                                margin-top: 1.5rem;
                                letter-spacing: 0.01rem;
                                font-family: $primaryFont;
                                font-size: 15px;
                                position: relative;
                                display: inline-block;

                                &.typeOfGiftinfos {
                                    margin-top: 23px
                                }
                            }

                            .typeOfGift::after {
                                content: '';
                                height: 2px;
                                width: 100px;
                                background: $lightBorder;
                                position: absolute;
                                left: 50%;
                                top: 27px;
                                transform: translate(-50%);
                            }

                            .typeOfGiftinfos::after {
                                content: '';
                                height: 2px;
                                width: 495px;
                                background: $lightBorder;
                                position: absolute;
                                left: 50%;
                                top: 27px;
                                transform: translate(-50%);
                            }
                        }
                    }

                    .chooseBalance {
                        width: 655px;
                        margin: 5% auto;
                        text-align: center;

                        select,
                        option {
                            width: 100%;
                        }

                        select {
                            border: 1px solid $runefangSteel;
                            margin-top: .5rem;
                            padding: .5rem 1rem;
                        }

                        .w-input {
                            border: 1px solid $runefangSteel;
                            padding: 1rem .5rem;
                            margin-top: 1%;
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 66%;

                            &::placeholder {
                                font-style: unset;
                            }

                            &.inputErr {
                                border: 1px solid $red;
                            }

                            &:focus {
                                outline: none;
                            }
                        }

                        .montant_title {
                            font-weight: 400;
                            font-family: $primaryFont;
                            font-size: 42px;
                            line-height: 50.4px;
                            font-style: italic;
                            text-transform: none;
                            position: absolute;
                            top: 3rem;
                            left: 50%;
                            transform: translateX(-50%);
                            white-space: nowrap;
                        }

                        .montant_subtitle {
                            font-weight: 400;
                            font-family: $primaryFont;
                            font-size: 15px;
                            line-height: 18px;
                            text-transform: none;
                            position: absolute;
                            top: 6.55rem;
                            left: 50%;
                            transform: translateX(-50%);
                        }

                        .montant_min_subtitle {
                            font-weight: 400;
                            font-family: $primaryFont;
                            font-size: 15px;
                            line-height: 18px;
                            text-transform: none;
                            font-style: italic;
                            position: absolute;
                            top: 7.7rem;
                            left: 50%;
                            transform: translateX(-50%);
                            color: #666666;
                        }
                    }
                }

                &.step2_infos {
                    h3 {
                        display: block;
                        font-family: $primaryFont;
                        font-weight: 400;
                        text-transform: none;
                        font-size: 15px;
                        margin: 7px 0 23px 0;
                    }

                    .js-textDeliveryMail {
                        display: none;
                    }

                    .js-textDeliveryMail_2 {
                        display: block !important;
                        font-size: 42px;
                        font-weight: 400;
                        line-height: 50.4px;
                        text-align: center;
                        font-family: $primaryFont;
                        font-style: italic;

                        &::after {
                            display: none;
                        }
                    }
                }

                .infos_msg {
                    margin: 0 30px;

                    input {
                        padding: 1rem;
                        border-radius: 0;
                        border: 1px solid $runefangSteel;

                        &:focus {
                            outline: none;
                        }
                    }

                    #s_msgPerso {
                        box-sizing: border-box;
                        border: 1px solid $runefangSteel;
                        font-family: Arial, Helvetica, sans-serif;
                        resize: none;
                        font-size: 0.83rem;

                        &.inputErr,
                        &.error-border {
                            border: 1px solid $red;
                        }
                    }

                    textarea {
                        padding: 1rem;
                        box-sizing: border-box;
                        height: 158px;
                    }

                    textarea:focus {
                        border: none;
                        outline: none;
                    }

                    .formPerso {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;

                        input {
                            margin-bottom: 1rem;
                            width: 44%;
                            font-family: Arial, Helvetica, sans-serif;
                        }

                        .w-input {
                            &.inputErr {
                                border: 1px solid $red;
                            }
                        }
                    }
                }
            }

            .btn_container {
                width: fit-content;
                display: flex;
                margin-top: 3.3rem;
                left: 1%;

                button {
                    margin: 0 1rem;
                    font-weight: 700;
                }
            }

            .button {
                width: 200px;
                margin: 0 auto;
            }
        }
    }

    #step2 {
        .btn_container {
            margin-top: 4%;

            #js-submitStep2 {
                span:nth-child(1) {
                    display: none;
                }

                span:nth-child(3) {
                    display: block !important;
                }
            }

            .button {
                width: 155px;
            }
        }
    }

    #step3 {
        .js-textDeliveryMail {
            display: block;
            font-size: 42px;
            font-style: italic;
            margin-top: 6px;

            &::after {
                display: none;
            }
        }

        .stepsList {
            display: flex;
            justify-content: space-between;
            padding: 0.8rem 3.3rem;
            margin: 1rem 0 0.5rem 0;
            align-items: flex-start;
            flex-direction: row;

            li {
                width: 455px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            h3 {
                text-transform: unset;
                font-size: 1rem;
            }

            p {
                width: 45px;
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                color: $black;
                font-weight: 500;
                font-size: 24px;
                border-radius: 50%;
                padding: 10px;
                background: $ctaColor;
                color: $whiteColor;
                font-family: $primaryFont;
            }

            .title_step_3 {
                text-transform: none;
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
                color: $ctaColor;
                font-family: $primaryFont;
                margin-top: 10px;
                width: 90%;
            }
        }

        .resumeList {
            margin-top: 3rem;
            position: relative;
            margin: 0 2.6rem auto;

            li {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                align-items: baseline;

                &:nth-of-type(1) {
                    h3 {
                        &:nth-of-type(1) {
                            display: none;
                        }

                        &:nth-of-type(2) {
                            display: block !important;
                        }
                    }
                }

                h3 {
                    text-transform: lowercase;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: 3px;
                    font-family: $ctaFont;
                }

                &:not(:last-child) {
                    margin-bottom: .9rem;
                }

                p,
                small {
                    margin-left: 4rem;
                }

                p {
                    font-size: 15px;
                    font-weight: 400;
                    color: $primaryColor;
                    font-family: $primaryFont;

                    &#js-message {
                        width: 55%;
                        margin-left: 1.1rem;
                    }

                    &#js-signature {
                        margin-left: 1.9rem;
                    }

                    &#js-balance {
                        margin-left: 5.4rem;
                    }

                    &#js-name {
                        margin-left: 4.6rem;
                    }
                }

                small {
                    font-size: 15px;
                    text-decoration: underline;
                    text-underline-offset: 3px;
                    font-weight: 400;
                    font-family: $primaryFont;
                }

                .modify {
                    cursor: pointer;
                    position: absolute;
                    left: 34rem;
                    color: $primaryColor;
                    text-transform: lowercase;
                }
            }
        }

        .giftDetails {
            margin-bottom: 3%;
        }
    }
}

body.cadeau_entreprise {
    &.de {
        .wrapper_content_cadeau {
            .contact .content_contact {
                .contact_conseil {
                    .sep-general {
                        margin: 30px 0 18px 0;
                    }

                    .sep {
                        margin: 20px 0 20px 0;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1200px) {
        .wrapper_content_cadeau {
            .contact {
                .content_contact {
                    .contact_conseil {
                        h3 {
                            font-size: 31px;
                        }

                        .mail_general {
                            font-size: .875rem;
                        }

                        .contact_info .mail {
                            font-size: .7rem;
                        }
                    }
                }

                .form_contact h3 {
                    font-size: 31px;
                }
            }
        }
    }

    @media only screen and (max-width: 850px) {
        .wrapper_content_cadeau {
            .contact {
                .content_contact {
                    .contact_conseil {
                        .mail_general {
                            font-size: .745rem;
                        }

                        .contact_info {
                            .mail {
                                font-size: .59rem;
                            }

                            p {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
    }
}